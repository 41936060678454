import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';

const LiveStreaming = () => {
    const matchId =271; // 고정된 matchId
    const [streamStatus, setStreamStatus] = useState('stopped');
    const [error, setError] = useState(null);
    const videoRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const chunksRef = useRef([]);

    const startWebcamStream = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    width: { ideal: 854 },
                    height: { ideal: 480 },
                    frameRate: { ideal: 30 }
                },
                audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                    sampleRate: 44100
                }
            });

            videoRef.current.srcObject = stream;

            await axios.post(`https://suportscore.site/api/live/webcam/start/${matchId}`);
            setStreamStatus('active');

            mediaRecorderRef.current = new MediaRecorder(stream, {
                mimeType: 'video/webm;codecs=vp8,opus',
                videoBitsPerSecond: 1000000 // 1Mbps
            });

            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    chunksRef.current.push(event.data);
                    sendChunkToServer();
                }
            };

            mediaRecorderRef.current.start(1000); // 1초마다 데이터 생성
        } catch (error) {
            console.error('Error starting webcam stream:', error);
            setError('Failed to start webcam stream: ' + error.message);
        }
    };

    const sendChunkToServer = async () => {
        if (chunksRef.current.length > 0) {
            const blob = new Blob(chunksRef.current, { type: 'video/webm' });
            chunksRef.current = [];

            const formData = new FormData();
            formData.append('video', blob, 'stream.webm');

            try {
                const response = await axios.post(`https://suportscore.site/api/live/webcam/frame/${matchId}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                console.log('Chunk sent successfully:', response.data);
            } catch (error) {
                console.error('Error sending video chunk:', error);
            }
        }
    };

    const stopWebcamStream = useCallback(async () => {
        try {
            // MediaRecorder 중지
            if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
                mediaRecorderRef.current.stop();
            }

            // 모든 트랙 중지 및 권한 해제
            if (videoRef.current && videoRef.current.srcObject) {
                const tracks = videoRef.current.srcObject.getTracks();
                tracks.forEach(track => {
                    track.stop();
                });
                videoRef.current.srcObject = null;
            }

            // 서버에 스트림 중지 요청
            await axios.post(`https://suportscore.site/api/live/webcam/stop/${matchId}`);
            setStreamStatus('stopped');

            // 남은 청크 데이터 정리
            chunksRef.current = [];

            // 미디어 장치 권한 해제 (선택적)
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
                stream.getTracks().forEach(track => track.stop());
            }

        } catch (error) {
            console.error('Error stopping webcam stream:', error);
            setError('Failed to stop webcam stream: ' + error.message);
        }
    }, []);

    useEffect(() => {
        // 컴포넌트 언마운트 시 정리 작업
        return () => {
            stopWebcamStream();
        };
    }, [stopWebcamStream]);

    useEffect(() => {
        // 페이지 이탈 시 자동 스트리밍 중지
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            stopWebcamStream();
            event.returnValue = '';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [stopWebcamStream]);

    return (
        <div className="live-streaming">
            <video ref={videoRef} style={{ width: '100%' }} autoPlay muted />
            {streamStatus === 'active' && <div className="live-status">LIVE (Webcam)</div>}
            {error && <p className="error-message">{error}</p>}
            <div className="stream-controls">
                <button onClick={startWebcamStream} disabled={streamStatus !== 'stopped'}>Start Webcam Stream</button>
                <button onClick={stopWebcamStream} disabled={streamStatus !== 'active'}>Stop Webcam Stream</button>
            </div>
        </div>
    );
};

export default LiveStreaming;