export const Sports = Object.freeze({
  SOCCER: '축구',
  BADMINTON: '배드민턴',
  TABLE_TENNIS: '탁구',
  DODGEBALL: '피구',
});

export const MatchTypes = Object.freeze({
  LEAGUE: '리그',
  TOURNAMENT: '토너먼트',
});

export const MatchStatuses = Object.freeze({
  FUTURE: '예정',
  LIVE: '진행중',
  PAST: '종료',
});
