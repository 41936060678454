import React from 'react';
import './SportsMenu.css';
import { Sports } from '../constant/Constants';

const SportsMenu = ({ selectedSport, onSelectSport, includeAll = true }) => {
  const sportsList = [
    { value: 'ALL', display: '전체' },
    { value: 'LIVE', display: 'LIVE' },
    ...Object.entries(Sports).map(([key, value]) => ({ value: key, display: value }))
  ];

  if (!includeAll) {
    sportsList.shift(); // 'ALL' 옵션 제거
  }

  return (
    <div className="sports-menu">
      {sportsList.map((sport) => (
        <div
          key={sport.value}
          className={`sports-menu-item ${selectedSport === sport.value ? 'active' : ''}`}
          onClick={() => onSelectSport(sport.value)}
        >
          {sport.display}
        </div>
      ))}
    </div>
  );
};

export default SportsMenu;