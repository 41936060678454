import MatchForm from "../../components/MatchForm";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axiosInstance from "../../api/axiosInstance";

const MatchEditPage = () => {
  const { matchId } = useParams();
  const [teams, setTeams] = useState([]);
  const [match, setMatch] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance.get(`/api/matches/${matchId}`)
      .then(response => {
        const data = response.data;
        const transformedData = {
          sports: data.sport,
          matchDate: data.date,
          startTime: data.startTime,
          matchType: data.matchType,
          matchStatus: data.status,
          round: data.round,
          teamOneName: data.teamName1,
          teamTwoName: data.teamName2,
          teamOneScore: data.teamScore1,
          teamTwoScore: data.teamScore2,
          teamOneSubScores: data.teamOneSubScores,
          teamTwoSubScores: data.teamTwoSubScores,
          showSubScores: data.teamOneSubScores !== "" || data.teamTwoSubScores !== "",
          groupName: data.groupName
        };
        setMatch(transformedData);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });

    axiosInstance.get(`/api/teams`)
      .then(response => {
        setTeams(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, [matchId]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let updatedValue = type === 'checkbox' ? checked : value;

    if (name === 'teamOneScore' || name === 'teamTwoScore') {
      updatedValue = parseInt(value, 10) || 0;
    }

    setMatch(prevForm => ({
      ...prevForm,
      [name]: updatedValue,
    }));
  };

  const getDepartmentByTeamName = (teamName) => {
    const team = teams.find(team => team.teamName === teamName);
    return team ? team.department : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedMatchForm = {
      ...match,
      teamOneName: getDepartmentByTeamName(match.teamOneName),
      teamTwoName: getDepartmentByTeamName(match.teamTwoName),
    };

    if (match.matchType === 'TOURNAMENT') {
      delete updatedMatchForm.groupName;

      axiosInstance.put(`/api/brackets/tournament/${matchId}`, updatedMatchForm)
        .then(response => {
          console.log(response.data)
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      axiosInstance.put(`/api/brackets/league/${matchId}`, updatedMatchForm)
        .then(response => {
          console.log(response.data)
        })
        .catch(error => {
          console.log(error)
        })
    }
    navigate('/adminmainpage')
  };

  return (
    <div className="match-edit-container">
      <h1>경기 수정</h1>
      {!loading ? (
        <MatchForm
          matchForm={match}
          matchId={matchId}
          handleInputChange={handleInputChange}
          teams={teams}
          handleSubmit={handleSubmit}
        />
      ) : (
        <>로딩 중...</>
      )}
    </div>
  )
}

export default MatchEditPage;