import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axiosInstance from "../../api/axiosInstance";

const LineUp = () => {
  const { matchId } = useParams();
  const [lineup, setLineup] = useState([]);

  useEffect(() => {
    const fetchLineup = async () => {
      try {
        const response = await axiosInstance.get(`/api/lineup/${matchId}`);
        setLineup(response.data);
      } catch (error) {
        console.error('Error fetching lineup:', error);
      }
    };

    fetchLineup();
  }, [matchId]);

  return (
    <div>
      {lineup.length > 0 ? lineup.map((team, index) => (
        <div key={index}>
          <h3>{team.department}</h3>
          <table className="table">
            <thead>
            <tr>
              <th>#</th>
              <th>player</th>
            </tr>
            </thead>
            <tbody>
            {team.players.map((player, idx) => (
              <tr key={idx}>
                <td>{idx + 1}</td>
                <td>{player}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      )) : <p>라인업 데이터가 없습니다.</p>}
    </div>
  )
}

export default LineUp;