import React, { useState, useEffect } from 'react';
import axiosInstance from '../../api/axiosInstance';
import './MatchManagementPage.css';
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { Sports, MatchTypes, MatchStatuses } from "../../constant/Constants";
import MatchForm from "../../components/MatchForm";

const MatchManagementPage = () => {
  const [matches, setMatches] = useState([]);
  const [teams, setTeams] = useState([]);
  const [matchForm, setMatchForm] = useState({
    sports: 'SOCCER',
    matchDate: '',
    startTime: '',
    matchType: 'LEAGUE',
    matchStatus: 'FUTURE',
    groupName: '',
    round: '',
    teamOneName: '',
    teamTwoName: '',
    teamOneScore: 0,
    teamTwoScore: 0,
    teamOneSubScores: '',
    teamTwoSubScores: '',
    showSubScores: false
  });

  const [editingMatchId, setEditingMatchId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchMatches();
    fetchTeams();
  }, []);

  const fetchMatches = async () => {
    try {
      const response = await axiosInstance.get('/api/matches/all');
      setMatches(response.data);
    } catch (error) {
      console.error('Error fetching matches:', error);
    }
  };

  const fetchTeams = async () => {
    try {
      const response = await axiosInstance.get('/api/teams');
      setTeams(response.data);
    } catch (error) {
      console.error('Error fetching teams:', error);
    }
  };

  const getDepartmentByTeamName = (teamName) => {
    const team = teams.find(team => team.teamName === teamName);
    return team ? team.department : null;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let updatedValue = type === 'checkbox' ? checked : value;

    if (name === 'teamOneScore' || name === 'teamTwoScore') {
      updatedValue = parseInt(value, 10) || 0;
    }

    setMatchForm(prevForm => ({
      ...prevForm,
      [name]: updatedValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedMatchForm = {
      ...matchForm,
      teamOneName: getDepartmentByTeamName(matchForm.teamOneName),
      teamTwoName: getDepartmentByTeamName(matchForm.teamTwoName),
    };

    if (matchForm.matchType === 'TOURNAMENT') {
      delete updatedMatchForm.groupName;
    }

    try {
      let response;
      if (!editingMatchId) {
        if (matchForm.matchType === 'LEAGUE') {
          response = await axiosInstance.post(`/api/brackets/league`, updatedMatchForm);
        } else {
          response = await axiosInstance.post(`/api/brackets/tournament`, updatedMatchForm);
        }
      } else {
        if (matchForm.matchType === 'LEAGUE') {
          response = await axiosInstance.put(`/api/brackets/league/${editingMatchId}`, updatedMatchForm);
        } else {
          response = await axiosInstance.put(`/api/brackets/tournament/${editingMatchId}`, updatedMatchForm);
        }
      }

      console.log('Server response:', response.data);
      resetForm();
      await fetchMatches();
    } catch (error) {
      console.error('Error saving match:', error);
    }
  };

  const resetForm = () => {
    setMatchForm({
      sports: 'SOCCER',
      matchDate: '',
      startTime: '',
      matchType: 'LEAGUE',
      matchStatus: 'FUTURE',
      groupName: '',
      round: '',
      teamOneName: '',
      teamTwoName: '',
      teamOneScore: 0,
      teamTwoScore: 0,
      teamOneSubScores: '',
      teamTwoSubScores: '',
      showSubScores: false
    });
    setEditingMatchId(null);
  };

  const handleEdit = (match) => {
    setMatchForm({
      sports: match.sport,
      matchDate: match.date,
      startTime: match.startTime,
      matchType: match.matchType,
      matchStatus: match.status,
      groupName: match.groupName,
      round: match.round,
      teamOneName: match.teamName1,
      teamTwoName: match.teamName2,
      teamOneScore: match.teamScore1,
      teamTwoScore: match.teamScore2,
      teamOneSubScores: match.teamOneSubScores || '',
      teamTwoSubScores: match.teamTwoSubScores || '',
      showSubScores: match.showSubScores || false
    });
    setEditingMatchId(match.matchId);
    console.log(match);
  };

  const handleDelete = async (matchId) => {
    try {
      await axiosInstance.delete(`/api/matches/${matchId}`);
      await fetchMatches();
    } catch (error) {
      console.error('Error deleting match:', error);
    }
  };

  return (
    <div className="match-management-container">
      <h1>경기 관리</h1>
      <Button className="margin-10" value="뒤로가기" onclick={() => navigate(-1)} />
      <MatchForm
        matchForm={matchForm}
        matchId={editingMatchId}
        handleInputChange={handleInputChange}
        teams={teams}
        handleSubmit={handleSubmit} />
      <div className="match-list">
        <h2>경기 목록</h2>
        {matches.map((match) => (
          <div key={match.matchId} className="match-item">
            <span>{match.teamName1} vs {match.teamName2}</span>
            <span>{Sports[match.sport]}</span>
            <span>{match.date}</span>
            <span>{match.startTime}</span>
            <span>{MatchTypes[match.matchType]}</span>
            <span>{MatchStatuses[match.status]}</span>
            <button onClick={() => handleEdit(match)}>수정</button>
            <button onClick={() => handleDelete(match.matchId)}>삭제</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MatchManagementPage;