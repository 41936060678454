import React, {useEffect, useRef} from 'react';
import { Bracket, Seed, SeedItem, SeedTeam } from 'react-brackets';
import "./TournamentBracket.css"

const TournamentBracket = ({ tournamentData }) => {

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: '2-digit', month: '2-digit', day: '2-digit', weekday: 'short' };
    return date.toLocaleDateString('en-GB', options).replace(',', '');
  };

  const CustomSeed = ({ seed, breakpoint }) => {
    const [teamA, teamB] = seed.teams || [{}, {}];

    return (
      <Seed mobileBreakpoint={breakpoint} className="seed">
        <SeedItem className="seed-item custom-style">
          <div className="seed-content">
            <div className="seed-date">
              {seed.date ? formatDate(seed.date) : 'No Date'}
            </div>
            <div className="seed-team-container">
              <div className="seed-team">
                <SeedTeam className={teamB?.name ? '' : 'no-team'}>
                  {teamA?.name || 'NO TEAM'}
                </SeedTeam>
              </div>
              <div className="seed-score">
                <SeedTeam className="seed-score-bold">
                  {teamA?.score !== undefined ? teamA.score : '-'}
                </SeedTeam>
              </div>
            </div>
            <div className="seed-team-container">
              <div className="seed-team">
                <SeedTeam className={teamB?.name ? '' : 'no-team'}>
                  {teamB?.name || 'NO TEAM'}
                </SeedTeam>
              </div>
              <div className="seed-score">
                <SeedTeam className="seed-score-bold">
                  {teamB?.score !== undefined ? teamB.score : '-'}
                </SeedTeam>
              </div>
            </div>
          </div>
        </SeedItem>
      </Seed>
    );
  };


  return (
    <div className="bracket-container">
      <div className="bracket-inner">
        <Bracket
          rounds={tournamentData}
          renderSeedComponent={CustomSeed}
          roundTitleComponent={(title) => (
            <div className="bracket-title">{title}</div>
          )}
        />
      </div>
    </div>
  );
};

export default TournamentBracket;
