import React, {useEffect, useState} from "react";
import axiosInstance from "../../api/axiosInstance";
import {useParams} from "react-router-dom";

const Details = () => {
  const { matchId} = useParams();
  const [match, setMatch] = useState([]);

  useEffect(() => {
    axiosInstance.get(`/api/matchTeams/${matchId}`)
      .then(response => {
        setMatch(response.data);
    })
      .catch(error => {
        console.log(error)
      })
  }, []);

  return (
    <div>
      <h2>역대 상대 전적</h2>
      <table className="table">
        <thead>
        <tr>
          <th>학과</th>
          <th>승</th>
          <th>무</th>
          <th>패</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{match.team1Department}</td>
          <td>{match.team1Wins}</td>
          <td>{match.team1Draws}</td>
          <td>{match.team1Losses}</td>
        </tr>
        <tr>
          <td>{match.team2Department}</td>
          <td>{match.team2Wins}</td>
          <td>{match.team2Draws}</td>
          <td>{match.team2Losses}</td>
        </tr>
        </tbody>
      </table>
      <h2>학과별 전적</h2>
      <div>
        <h3>{match.team1Department}</h3>
        <table className="table">
          <thead>
          <tr>
            <th>학과</th>
            <th>승</th>
            <th>무</th>
            <th>패</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{match.team2Department}</td>
            <td>{match.matchWins}</td>
            <td>{match.matchDraws}</td>
            <td>{match.matchLosses}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h3>{match.team2Department}</h3>
        <table className="table">
          <thead>
          <tr>
            <th>학과</th>
            <th>승</th>
            <th>무</th>
            <th>패</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{match.team1Department}</td>
            <td>{match.matchLosses}</td>
            <td>{match.matchDraws}</td>
            <td>{match.matchWins}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Details;