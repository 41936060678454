import React, { useEffect, useRef, useState, useCallback } from 'react';
import axios from 'axios';
import Hls from 'hls.js';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './LivePage.css';
import axiosInstance from "../api/axiosInstance";

const LivePage = () => {
  const videoRef = useRef(null);
  const [error, setError] = useState(null);
  const hlsRef = useRef(null);

  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [chatUser, setChatUser] = useState(null);

  const [roomId, setRoomId] = useState('');
  const [userCount, setUserCount] = useState(0);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [nickname, setNickname] = useState('');
  const clientRef = useRef(null);
  const subscribedRef = useRef(false);

  // WebSocket 연결 함수
  const connectWebSocket = useCallback((roomId, nickname) => {
    if (clientRef.current && clientRef.current.connected) {
      console.log('WebSocket already connected');
      return;
    }

    const client = new Client({
      webSocketFactory: () => new SockJS('https://suportscore.site/ws-stomp', null, { withCredentials: true }),
      connectHeaders: { nickname },
      debug: (str) => console.log(str),
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    });

    client.onConnect = () => {
      console.log('WebSocket Connected');
      if (!subscribedRef.current) {
        client.subscribe(`/sub/chat/room/${roomId}`, (message) => {
          const receivedMessage = JSON.parse(message.body);
          setMessages((prevMessages) => [receivedMessage, ...prevMessages]);
          setUserCount(receivedMessage.userCount);
        });
        subscribedRef.current = true;
      }
    };

    client.onStompError = (frame) => {
      console.error('STOMP error', frame);
    };

    client.activate();
    clientRef.current = client;
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const roomIdFromUrl = urlParams.get('roomId') || `Room-${Math.floor(Math.random() * 9000) + 1000}`;
    setRoomId(roomIdFromUrl);
    fetchCurrentUser(roomIdFromUrl);

    return () => {
      handleExit();
    };
  }, []);

  const fetchCurrentUser = async (roomIdFromUrl) => {
    try {
      const response = await axiosInstance.get('/api/users/me');
      console.log('Fetched User:', response.data);
      const userNickname = response.data.nickname || 'Anonymous';
      setNickname(userNickname);
      connectWebSocket(roomIdFromUrl, userNickname);
    } catch (error) {
      console.error('Could not fetch user info:', error);
      setNickname('Anonymous');
      connectWebSocket(roomIdFromUrl, 'Anonymous');
    }
  };

  const sendMessage = useCallback(() => {
    if (message.trim() === '' || !clientRef.current || !clientRef.current.connected) {
      console.log('Cannot send message: client not connected or empty message');
      return;
    }

    clientRef.current.publish({
      destination: `/pub/chat/message/${roomId}`,
      body: JSON.stringify({
        type: 'TALK',
        roomId: roomId,
        sender: nickname,
        message: message,
      }),
    });

    setMessage('');
  }, [message, roomId, nickname]);

  const handleExit = useCallback(() => {
    if (clientRef.current && clientRef.current.connected) {
      clientRef.current.publish({
        destination: `/pub/chat/message/${roomId}`,
        body: JSON.stringify({
          type: 'QUIT',
          roomId: roomId,
          sender: nickname,
        }),
      });
      clientRef.current.deactivate();
      subscribedRef.current = false;
    }
  }, [roomId, nickname]);

  // HLS Streaming useEffect
  useEffect(() => {
    const hardcordMatchId = 271; // 고정된 matchId
    const streamUrl = `https://suportscore.site/hls/${hardcordMatchId}.m3u8`;
    console.log("Loading HLS stream from URL:", streamUrl);

    if (Hls.isSupported()) {
      hlsRef.current = new Hls({
        debug: false,
        enableWorker: true,
        lowLatencyMode: true,
        backBufferLength: 90
      });
      hlsRef.current.loadSource(streamUrl);
      hlsRef.current.attachMedia(videoRef.current);
      hlsRef.current.on(Hls.Events.MANIFEST_PARSED, () => {
        console.log("HLS manifest parsed");
        // 자동 재생 제거: 여기서 videoRef.current.play()를 호출하지 않음
      });
      hlsRef.current.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              hlsRef.current.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              hlsRef.current.recoverMediaError();
              break;
            default:
              setError(`Fatal error: ${data.details}`);
              break;
          }
        }
      });
    } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
      videoRef.current.src = streamUrl;
    } else {
      setError("This browser does not support HLS streaming. Please try a different browser.");
    }

    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
    };
  }, []);

  return (
      <div className="live-page">
        <Header />
        <div className="live-container" style={{ maxWidth: '800px', margin: '20px auto', width: '100%' }}>
          <div className="live-video" style={{ position: 'relative', paddingTop: '56.25%', background: '#000' }}>
            <video
                ref={videoRef}
                controls // 기본 비디오 컨트롤 사용
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain'
                }}
            />
            <div className="live-status" style={{
              position: 'absolute',
              top: '10px',
              left: '10px',
              background: 'rgba(255, 0, 0, 0.7)',
              color: 'white',
              padding: '5px 10px',
              borderRadius: '5px',
              fontSize: '14px',
              fontWeight: 'bold',
              zIndex: 1000 // LIVE 표시가 컨트롤 위에 나타나도록 함
            }}>LIVE</div>
          </div>
          {error && <p className="error-message" style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>{error}</p>}
        </div>
        <div className="chat-container">
          <h1>채팅방 (방 ID: {roomId})</h1>
          <h4>현재 사용자 수: {userCount}</h4>
          <div className="input-group">
            <input
                type="text"
                className="form-control"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                placeholder="메시지를 입력하세요..."
            />
            <button className="btn btn-primary" onClick={sendMessage}>
              보내기
            </button>
            <button className="btn btn-danger" onClick={handleExit}>
              퇴장하기
            </button>
          </div>
          <ul className="list-group">
            {messages.map((msg, index) => (
                <li key={index} className="list-group-item">
                  {msg.sender}: {msg.message}
                </li>
            ))}
          </ul>
        </div>
        <Footer />
      </div>
  );
};

export default LivePage;