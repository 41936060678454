import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axiosInstance from "../../api/axiosInstance";

const Comments = () => {
  const { matchId } = useParams();
  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axiosInstance.get(`/api/comments/${matchId}`);
        setComments(response.data);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };

    fetchComments();
  }, [matchId]);

  const handleCommentSubmit = async () => {
    if (newComment.trim()) {
      try {
        const response = await axiosInstance.post(`/api/comments/${matchId}`, {
          matchId,
          text: newComment
        });
        setComments([...comments, response.data]);
        setNewComment('');
      } catch (error) {
        console.error('Error posting comment:', error);
      }
    }
  };


  return (
    <div className="comments-section">
      <h3>응원 댓글</h3>
      <div className="comments">
        {comments.map((comment, index) => (
          <div key={index} className="comment">
            <span className="comment-user-icon">👤</span>
            <span className="comment-text">{comment.text}</span>
          </div>
        ))}
      </div>
      <div className="comment-input-section">
        <input
          type="text"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="채팅을 입력해 주세요 (최대 300자)"
          maxLength="300"
        />
        <button onClick={handleCommentSubmit} className="comment-submit-button">✈️</button>
      </div>
    </div>
  )
}

export default Comments;