import React from "react";
import "./SelectGroup.css"

const SelectGroup = ({ name, value, handleInputChange, title, body, type ,data, placeHolder }) => {
  const displayValue = value !== undefined && value !== null ? value : '';

  const renderOption = () => {
    switch (type) {
      case 'selectTeam':
        return (
          <select
            name={name}
            value={displayValue}
            onChange={handleInputChange}
            required
          >
            <option value="">{body}</option>
            {data.map((team) => (
              <option key={team.teamId} value={team.teamName}>
                {team.teamName}
              </option>
            ))}
          </select>
        )
      case 'selectSport':
        return (
          <select
            name={name}
            value={displayValue}
            onChange={handleInputChange}
            required
          >
            {Object.entries(data).map(([key, val]) => (
              <option key={key} value={key}>
                {val}
              </option>
            ))}
          </select>
        )
      case 'inputDate':
        return (
          <input
            type="date"
            name="matchDate"
            value={displayValue}
            onChange={handleInputChange}
            required
          />
        )
      case 'inputTime':
        return (
          <input
            type="time"
            name="startTime"
            value={displayValue}
            onChange={handleInputChange}
            required
          />
        )
      case 'inputText':
        return (
          <input
            type="text"
            name={name}
            value={displayValue}
            onChange={handleInputChange}
            placeholder={placeHolder}
          />
        )
      default:
    }
  }

  return (
    <div className="input-group">
      <label>{title}</label>
      {renderOption()}
    </div>
  )
}

export default SelectGroup