import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { ThemeProvider } from './contexts/ThemeContext';
import routes from "./routes";

const App = () => {
  const renderRoutes = (routes) => {
    return routes.map((route, index) => {
      if (route.children) {
        return (
          <Route
            key={index}
            path={route.path}
            element={route.element}
          >
            {renderRoutes(route.children)}
          </Route>
        );
      }

      return (
        <Route
          key={index}
          path={route.path}
          element={route.element}
          exact={route.exact}
        />
      );
    });
  };

  return (
    <div className="app-container">
      <Router>
        <ThemeProvider>
          <Routes>
            {renderRoutes(routes)}
          </Routes>
        </ThemeProvider>
      </Router>
    </div>
  );
};

export default App;
