import React, { useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './MatchDetailPage.css';

const MatchDetailPage = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('details');

  return (
    <div className="match-detail-page">
      <Header />
      <button onClick={() => navigate(-1)} className="back-button">&lt;</button>
      <div className="match-detail-content">
        <h1>경기 자세히 보기</h1>
        <div className="tabs">
          <button className={`tab-button ${activeTab === 'details' ? 'active' : ''}`} onClick={() => { setActiveTab('details'); navigate('details'); }}>경기 전적</button>
          <button className={`tab-button ${activeTab === 'lineup' ? 'active' : ''}`} onClick={() => { setActiveTab('lineup'); navigate('line-up'); }}>라인업</button>
          <button className={`tab-button ${activeTab === 'comments' ? 'active' : ''}`} onClick={() => { setActiveTab('comments'); navigate('comments'); }}>댓글</button>
        </div>
        <div className="tab-content">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MatchDetailPage;
