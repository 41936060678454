import SelectGroup from "./SelectGroup";
import {MatchStatuses, MatchTypes, Sports} from "../constant/Constants";
import Button from "./Button";
import React from "react";
import {useNavigate} from "react-router-dom";
import "../pages/admin/MatchManagementPage.css"

const MatchForm = ({ matchForm, matchId, handleInputChange, teams, handleSubmit }) => {
  const navigate = useNavigate();

  return (
    <div className="match-form">
      <SelectGroup
        name="teamOneName"
        value={matchForm.teamOneName}
        handleInputChange={handleInputChange}
        title="팀 1"
        body="팀 선택"
        type="selectTeam"
        data={teams}
      />
      <SelectGroup
        name="teamTwoName"
        value={matchForm.teamTwoName}
        handleInputChange={handleInputChange}
        title="팀 2"
        body="팀 선택"
        type="selectTeam"
        data={teams}
      />
      <SelectGroup
        name="sports"
        value={matchForm.sports}
        handleInputChange={handleInputChange}
        title="스포츠"
        type="selectSport"
        data={Sports}
      />
      <SelectGroup
        name="matchDate"
        value={matchForm.matchDate}
        handleInputChange={handleInputChange}
        title="날짜"
        type="inputDate"
      />
      <SelectGroup
        name="startTime"
        value={matchForm.startTime}
        handleInputChange={handleInputChange}
        title="시작 시간"
        type="inputTime"
      />
      <SelectGroup
        name="matchType"
        value={matchForm.matchType}
        handleInputChange={handleInputChange}
        title="경기 타입"
        type="selectSport"
        data={MatchTypes}
      />
      <SelectGroup
        name="matchStatus"
        value={matchForm.matchStatus}
        handleInputChange={handleInputChange}
        title="상태"
        type="selectSport"
        data={MatchStatuses}
      />
      {(matchForm.matchType === 'LEAGUE' || matchForm.matchType === 'league') &&
        <SelectGroup
          name="groupName"
          value={matchForm.groupName}
          handleInputChange={handleInputChange}
          title="그룹명"
          type="inputText"
          placeHolder="A조, B조, C조... "
        />
      }
      <SelectGroup
        name="round"
        value={matchForm.round}
        handleInputChange={handleInputChange}
        title="라운드"
        type="inputText"
        placeHolder="리그 : 1 round, 2 round.. / 토너먼트 : QUARTER_FINALS, SEMI_FINALS, FINAL"
      />
      {matchId &&
        <>
          <SelectGroup
            name="teamOneScore"
            value={matchForm.teamOneScore}
            handleInputChange={handleInputChange}
            title={`${matchForm.teamOneName} 점수`}
            type="inputText"
          />
          <SelectGroup
            name="teamTwoScore"
            value={matchForm.teamTwoScore}
            handleInputChange={handleInputChange}
            title={`${matchForm.teamTwoName} 점수`}
            type="inputText"
          />
        </>
      }
      {matchForm.showSubScores &&
        <>
          <SelectGroup
            name="teamOneSubScores"
            value={matchForm.teamOneSubScores}
            handleInputChange={handleInputChange}
            title={`${matchForm.teamOneName} 서브 스코어`}
            type="inputText"
          />
          <SelectGroup
            name="teamTwoSubScores"
            value={matchForm.teamTwoSubScores}
            handleInputChange={handleInputChange}
            title={`${matchForm.teamTwoName} 서브 스코어`}
            type="inputText"
          />
        </>
      }
      {matchId &&
        <label>
          <input
            type="checkbox"
            name="showSubScores"
            checked={matchForm.showSubScores}
            onChange={handleInputChange}
          />
          서브 스코어 표시
        </label>
      }
      <Button
        className="save-button"
        onclick={handleSubmit}
        value="수정"
      />
      <Button
        className="save-button"
        onclick={() => navigate(-1)}
        value="취소"
      />
    </div>
  )
}

export default MatchForm;