import React, {useEffect, useState} from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './BracketPage.css';
import axiosInstance from "../../api/axiosInstance";
import LeagueTable from "../../components/LeagueTable";
import TournamentBracket from "../../components/TournamentBracket";
import { Sports } from "../../constant/Constants";


const BracketPage = () => {
  const [selectedSport, setSelectedSport] = useState('SOCCER');
  const [activeTab, setActiveTab] = useState('league');
  const [leagueData, setLeagueData] = useState([]);
  const [tournamentData, setTournamentData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true); // 데이터 로딩 시작
    axiosInstance.get(`/api/brackets/${activeTab}/${selectedSport}`)
      .then(response => {
        if (activeTab === 'league') setLeagueData(response.data);
        else setTournamentData(transformData(response.data));

        setLoading(false); // 데이터 로딩 완료
      })
      .catch(error => {
        console.log('대진표 에러', error);
        setLoading(false); // 데이터 로딩 완료 (실패)
      });
  }, [activeTab, selectedSport]);

  const transformData = (data) => {
    const groupedData = data.reduce((acc, match) => {
      const round = match.tournamentRoundText;
      if (!acc[round]) acc[round] = [];
      acc[round].push({
        id: match.id,
        date: match.startTime,
        teams: match.participants.map(p => ({
          name: p.name,
          score: p.resultText,
          winner: p.winner,
          id: p.id
        })),
        nextMatchId: match.nextMatchId
      });
      return acc;
    }, {});

    // 라운드 순서
    const roundOrder = ["QUARTER_FINALS", "SEMI_FINALS", "FINAL"];

    roundOrder.forEach((round, index) => {
      const nextRound = roundOrder[index + 1];
      if (nextRound) {
        const matchesInCurrentRound = groupedData[round];

        // 현재 라운드의 경기 수가 짝수인지 확인
        if (matchesInCurrentRound && matchesInCurrentRound.length % 2 === 0) {
          // 다음 라운드에 필요한 경기 수 계산 (짝수개의 경기 / 2)
          const requiredNextRoundMatches = matchesInCurrentRound.length / 2;

          // 다음 라운드가 없거나 경기가 부족하면 빈 경기 슬롯을 생성
          if (!groupedData[nextRound] || groupedData[nextRound].length < requiredNextRoundMatches) {
            groupedData[nextRound] = groupedData[nextRound] || [];

            while (groupedData[nextRound].length < requiredNextRoundMatches) {
              groupedData[nextRound].push({
                id: null, // 아직 생성되지 않은 경기
                date: null,
                teams: [{ name: null }, { name: null }], // 빈 팀 슬롯
                nextMatchId: null
              });
            }
          }
        }

        // 기존 경기 연결 로직
        matchesInCurrentRound.forEach(match => {
          if (match.nextMatchId) {
            const nextMatch = groupedData[nextRound]?.find(m => m.id === match.nextMatchId);
            if (nextMatch) {
              const winningTeam = match.teams.find(team => team.winner);
              if (winningTeam) {
                if (!nextMatch.teams[0].name) {
                  nextMatch.teams[0] = winningTeam;
                } else if (!nextMatch.teams[1].name) {
                  nextMatch.teams[1] = winningTeam;
                }
              }
            }
          }
        });
      }
    });

    return roundOrder.map(round => ({
      title: round.replace('_', ' '),
      seeds: groupedData[round] || [],
    }));
  };

  return (
    <div>
      <Header />
      <div className="bracket-page light">
        <header>
          <h1>대진표</h1>
        </header>
        <div className="content">
          <div className="filters">
            <select value={selectedSport} onChange={(e) => setSelectedSport(e.target.value)}>
              {Object.entries(Sports).map(([key, val]) => (
                <option key={key} value={key}>
                  {val}
                </option>
              ))}
            </select>
            <div className="tabs">
              <button className={`tab-button ${activeTab === 'league' ? 'active' : ''}`}
                      onClick={() => setActiveTab('league')}>
                리그
              </button>
              <button className={`tab-button ${activeTab === 'tournament' ? 'active' : ''}`}
                      onClick={() => setActiveTab('tournament')}>
                토너먼트
              </button>
            </div>
          </div>
          <div className="body-container">
            {loading
              ? <p>로딩 중...</p>
              : (activeTab === 'league'
                  ? <LeagueTable leagueData={leagueData}/>
                  : <TournamentBracket tournamentData={tournamentData}/>
              )
            }
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default BracketPage;