import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import Header from '../components/Header';
import SportsMenu from '../components/SportsMenu';
import Footer from '../components/Footer';
import './MainPage.css';
import userImage from '../assets/user-image.png';
import starIcon from '../assets/star.png';
import calendarIcon from '../assets/calendar.png';
import menuIcon from '../assets/menu.png';
import { format } from 'date-fns';

const MainPage = () => {
  const [matches, setMatches] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null)

  const [selectedSport, setSelectedSport] = useState('ALL');
  const [date, setDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  useEffect(() => {
    const params = {};
    if (selectedSport) params.sport = selectedSport;
    if (date) params.date = date;

    axiosInstance.get(`/api/matches`, { params })
      .then(response => {
        setMatches(response.data);
      })
      .catch(error => {
        console.log('경기 불러오기 에러', error)
        setError('경기 정보를 불러오는 데 실패했습니다.');
        setLoading(false);
      })
  }, [selectedSport, date]);

  // useEffect(() => {
  //   console.log(userInfo.userId)
  //   axiosInstance.get(`/api/users/${userInfo.userId}/bookmark`)
  //     .then(response => {
  //       setFavorites(response.data.map(fav => fav.matchId));
  //     })
  //     .catch(error => {
  //       console.log('즐겨찾기 불러오기 에러', error);
  //     });
  // }, [userInfo.userId]);

  const addToFavorites = (matchId) => {
    axiosInstance.post(`/api/users/${userInfo.userId}/bookmark`, { matchId })
      .then(response => {
        setFavorites(prevFavorites => [...prevFavorites, response.data.matchId]);
      })
      .catch(error => {
        console.log('즐겨찾기 추가 에러', error);
      });
  };

  const removeFromFavorites = (favoriteId) => {
    axiosInstance.delete(`/api/users/${userInfo.userId}/bookmark/${favoriteId}`)
      .then(() => {
        setFavorites(prevFavorites => prevFavorites.filter(id => id !== favoriteId));
      })
      .catch(error => {
        console.log('즐겨찾기 제거 에러', error);
      });
  };

  const handleFavoriteToggle = (matchId, favoriteId) => {
    if (favorites.includes(matchId)) {
      removeFromFavorites(favoriteId);
    } else {
      addToFavorites(matchId);
    }
  };

  const handleMatchClick = (matchId, sport) => {
    navigate(`/matchDetail/${sport}/${matchId}/details`);
  };

  const groupedMatches = matches.reduce((acc, match) => {
    if (!acc[match.sport]) {
      acc[match.sport] = [];
    }
    acc[match.sport].push(match);
    return acc;
  }, {});

  const getStatusLabel = (status) => {
    switch(status) {
      case 'FUTURE': return '예정';
      case 'LIVE': return 'LIVE';
      case 'PAST': return '종료';
      default: return status;
    }
  };

  return (
    <div className="main-page">
      <Header />
      <img
        src={menuIcon}
        alt="menu icon"
        onClick={() => navigate('/mypage')}
        className="menu-icon"
        style={{cursor: 'pointer'}}
      />
      <SportsMenu selectedSport={selectedSport} onSelectSport={setSelectedSport}/>
      <div className="main-content">
        <div className="header-section">
          <div className="date-picker">
            <div className="date">{date}</div>
            <Flatpickr
              value={date}
              onChange={date => setDate(format(date[0], 'yyyy-MM-dd'))}
              options={{ dateFormat: "Y-m-d", wrap: true }}
            >
              <div className="calendar-container">
                <input type="text" data-input style={{ display: 'none' }} />
                <button className="calendar-button" type="button" data-toggle>
                  <img src={calendarIcon} alt="Calendar" className="calendar-icon" />
                </button>
              </div>
            </Flatpickr>
          </div>
        </div>
        {loading && <p>로딩 중...</p>}
        {error && <p className="error-message">{error}</p>}
        {!loading && !error && Object.keys(groupedMatches).map((sport, index) => (
          <div key={index} className="match-section">
            <div className="match-title">{sport}</div>
            {groupedMatches[sport].map((match, idx) => (
              <div
                key={idx}
                className="match-item"
                onClick={() => handleMatchClick(match.matchId, sport)}
              >
                <img src={userImage} alt="user" className="user-image2"/>
                <div className="match-team-info">
                  <div className="team-name">{match.teamName1}</div>
                  <div className="score">{match.teamScore1}</div>
                </div>
                <button
                  className={match.status === 'LIVE' ? 'live-button' : 'end-button'}
                >
                  {getStatusLabel(match.status)}
                </button>
                <div>
                  <div className="team-name">{match.teamName2}</div>
                  <div className="score">{match.teamScore2}</div>
                </div>
                <img src={userImage} alt="user" className="user-image2"/>
                <img
                  src={starIcon}
                  alt="star"
                  className={"star-icon"}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFavoriteToggle(match.matchId, match.favoriteId);
                  }}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default MainPage;
