import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../api/axiosInstance';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import Header from '../../components/Header';
import SportsMenu from '../../components/SportsMenu';
import Footer from '../../components/Footer';
import '../MainPage.css';
import calendarIcon from '../../assets/calendar.png';

const AdminMainPage = () => {
  const [matches, setMatches] = useState([]);
  const [date, setDate] = useState(new Date());
  const [selectedSport, setSelectedSport] = useState('ALL');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchMatches();
  }, [selectedSport, date]);

  const fetchMatches = async () => {
    setLoading(true);
    setError(null);
    const params = {};
    if (selectedSport) params.sport = selectedSport;
    if (date) params.date = date;

    try {
      const params = new URLSearchParams();
      if (selectedSport !== 'ALL') {
        params.append('sport', selectedSport);
      }
      params.append('date', formatDate(date));

      const response = await axiosInstance.get('/api/matches', { params });
      setMatches(response.data);
    } catch (error) {
      console.error('Error fetching matches:', error);
      setError('경기 정보를 불러오는 데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteMatch = async (matchId) => {
    if (window.confirm('정말로 이 경기를 삭제하시겠습니까?')) {
      try {
        await axiosInstance.delete(`/api/matches/${matchId}`);
        fetchMatches();
      } catch (error) {
        console.error('Error deleting match:', error);
        setError('경기 삭제에 실패했습니다.');
      }
    }
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const day = ('0' + d.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  return (
    <div className="main-page">
      <Header />
      <SportsMenu
        selectedSport={selectedSport}
        onSelectSport={setSelectedSport}
        includeAll={true}
      />
      <div className="admin-title">
        <h1>관리자 페이지</h1>
        <div className="create-buttons">
          <button className="margin-5" onClick={() => navigate('/admin/create-match')}>경기 생성</button>
          <button className="margin-5" onClick={() => navigate('/admin/create-team')}>팀 생성</button>
        </div>
      </div>
      <div className="content">
        <div className="header-section">
          <div className="date-picker">
            <div className="date">{formatDate(date)}</div>
            <Flatpickr
              value={date}
              onChange={date => setDate(date[0])}
              options={{ dateFormat: "Y-m-d", wrap: true }}
            >
              <div className="calendar-container">
                <input type="text" data-input style={{display: 'none'}}/>
                <button className="calendar-button" type="button" data-toggle>
                  <img src={calendarIcon} alt="Calendar" className="calendar-icon"/>
                </button>
              </div>
            </Flatpickr>
          </div>
        </div>
      </div>
      {error && <p className="error-message">{error}</p>}
      <div className="matches-section">
        {loading ? (
          <p>로딩 중...</p>
        ) : matches.length > 0 ? (
          <table>
            <thead>
            <tr>
              <th>날짜</th>
              <th>시간</th>
              <th>스포츠</th>
              <th>팀1</th>
              <th>팀2</th>
              <th>상태</th>
              <th>액션</th>
            </tr>
            </thead>
            <tbody>
            {matches.map((match) => (
              <tr key={match.matchId}>
                <td>{match.date}</td>
                <td>{match.startTime}</td>
                <td>{match.sport}</td>
                <td>{match.teamName1}</td>
                <td>{match.teamName2}</td>
                <td>{match.status}</td>
                <td>
                  <button className="margin-5" onClick={() => navigate(`/admin/edit-match/${match.matchId}`)}>수정</button>
                  <button className="margin-5" onClick={() => handleDeleteMatch(match.matchId)}>삭제</button>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        ) : (
          <p>표시할 경기가 없습니다.</p>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default AdminMainPage;